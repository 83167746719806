.Signup {
  .margin-left-style {
    margin-left: 25%;
    @media (max-width: 580px) {
          margin-left: 0%;
      }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #faad14;
    border-color: #faad14;
    border-radius: 4px;
    height: 20px;
    width: 20px;
  }

 .button.ant-btn.ant-btn-yellow  {
    width: '100%';
    margin-left: '0%';
  }
}

.signupform-p {
  color: #fff;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.04em;
  
}

.signupform-p:before { content:" * "; color: #e30; }
