.MyProfile {
    .profileform-wrap {
        @media (max-width: 580px) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

.profile-form-container {
    max-width: 800px;
    margin: 0 auto;
}