@import "../../styles/elements";

.single-horoscope-content {
    padding-top: 66px;
    .page-title {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 5px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 170%;
        letter-spacing: 0.04em;
        color: $color-theme-yellow;
    }

    .page-subtitle {
        margin-bottom: 20px;
        font-size: 16px;
    }

    p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        text-align: justify;
        letter-spacing: 0.04em;
        color: #FFFFFF;

    }

    .horoscope-description-block {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        text-align: justify;
        letter-spacing: 0.04em;
        color: #FFFFFF;
        white-space: pre-wrap;
        margin-bottom: 30px;
    }

    .chapter-block {
        div {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            text-align: justify;
            letter-spacing: 0.04em;
            color: #FFFFFF;
            white-space: pre-wrap;
        }
        margin-bottom: 30px;
        scroll-margin-top: 66px;
    }

    h2, h3 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 170%;
        /* or 31px */
        text-align: center;
        letter-spacing: 0.04em;
        color: $color-theme-yellow;
        margin-bottom: 30px;
    }

    .video-block {
        display: flex;
        align-items: center;
        margin-top: 0px;
        position: relative;
        margin-bottom: 25px;
        iframe {
            width: 100%;
            height: 425px;
           // max-width: 550px;
            margin: 0 auto;
            justify-self: center;
        } 
    }
    button.ant-btn.ant-btn-primary.one-off-buy-btn-style {
        background: '#E1BF40';
        border-radius: 15px;
        width: 350px;
        height: 60px;
    }
    .one-off-buy-btn-wrapper {
        text-align: center;
        margin-bottom: 30px;
    }
    .one-off-buy-btn-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.38px;
        text-transform: capitalize;
        color: #000000;
    }
    @media screen and (max-width: 458px) {
        button.ant-btn.ant-btn-primary.one-off-buy-btn-style {
            width: 300px;
            height: 50px;
        }
    }
    @media screen and (min-width: 720px) and (max-width: 800px) {
        .video-block {
            display: flex;
            iframe {
                width: 100%;
                height: 410px;
                margin: 0 auto;
                justify-self: center;
            } 
        }
    }
    @media screen and (min-width: 800px) and (max-width: 950px) {
        .video-block {
            display: flex;
            iframe {
                width: 100%;
                height: 430px;
                margin: 0 auto;
                justify-self: center;
            } 
        }
    }
    @media screen and (max-width: 512px) {
        .video-block {
            display: flex;
            iframe {
                width: 100%;
                height: 200px;
                margin: 0 auto;
                justify-self: center;
            } 
        }
    }
    
}
