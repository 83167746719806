@import url(~antd/dist/antd.css);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italiana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap");
@font-face {
  font-family: 'Nato Sans';
  src: local("Nato Sans"), url(../assets/fonts/Noto_Sans/NotoSans-Regular.ttf) format("truetype"); }

@font-face {
  font-family: 'Inter';
  src: local("Inter"), url(../assets/fonts/Inter/Inter-Regular.ttf) format("truetype"); }

@font-face {
  font-family: 'Nunito';
  src: local("Nunito"), url(../assets/fonts/Nunito/Nunito-Regular.ttf) format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Nunito';
  src: local("Nunito"), url(../assets/fonts/Nunito/Nunito-Light.ttf) format("truetype");
  font-weight: 400; }

body {
  font-size: 14px;
  background: url("../assets/images/background.webp") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: Inter;
  color: #ffffff; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  border-radius: 0px;
  background: #000000; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1bf40;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e1bf40; }

:root {
  scrollbar-color: #e1bf40 #000000 !important;
  scrollbar-width: thin !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff; }

a {
  color: #e1bf40;
  text-decoration: underline;
  font-family: Inter; }
  a:hover {
    color: #e1bf40; }

.ant-btn.ant-btn-link {
  color: #e1bf40; }
  .ant-btn.ant-btn-link span {
    text-decoration: underline; }
  .ant-btn.ant-btn-link:hover {
    color: #e1bf40; }
    .ant-btn.ant-btn-link:hover span {
      text-decoration: none; }

.ant-btn.ant-btn-grey {
  width: 100%;
  height: 63px;
  background: #818181;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border: none; }
  .ant-btn.ant-btn-grey:hover {
    background-color: #e1bf40; }

.ant-btn.ant-btn-gold {
  width: 100%;
  height: 63px;
  background: #e1bf40;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  border: none; }
  .ant-btn.ant-btn-gold:hover {
    background-color: #ffffff; }

.ant-btn.sidebar-btn {
  width: 100%;
  height: 63px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  border: none;
  padding-left: 36px; }
  .ant-btn.sidebar-btn:hover {
    background-color: rgba(255, 255, 255, 0.4);
    color: #ffffff; }
  .ant-btn.sidebar-btn img {
    margin-left: auto; }

.ant-select-dropdown {
  background: linear-gradient(130.89deg, #ffbc03 -10.99%, #3c3c3c 58.57%);
  border-radius: 8px; }

.ant-select-item {
  min-height: 40px;
  padding: 10px 20px; }

.ant-select-item-option-content {
  color: #fff; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: linear-gradient(130.89deg, rgba(225, 191, 64, 0.2184) -10.99%, rgba(60, 60, 60, 0.28) 58.57%); }

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #F5C451; }

.ant-form-item-label > label {
  color: #fff;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.04em; }

.title-text {
  color: #ffffff;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 24px;
  margin-top: 18.79%;
  margin-top: 50px !important; }

.sub-title-text {
  color: #ffffff;
  text-align: center;
  font-family: 'Noto Sans';
  font-weight: 600;
  font-size: 24px; }

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: #fff0;
  border: 0;
  border-bottom: 2px solid #ffffff; }

.ant-input {
  background-color: #fff0;
  border: 0;
  border-bottom: 2px solid #fff;
  color: #ffffff;
  margin-bottom: 10px; }
  .ant-input:focus, .ant-input:hover {
    border-bottom: 2px solid #e1bf40;
    box-shadow: none; }

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  box-shadow: none; }

.ant-picker {
  display: block;
  background-color: #fff0;
  border: 0;
  border-bottom: 1px solid #fff;
  color: #ffffff; }

.ant-picker-input > input {
  color: #ffffff;
  background-color: #fff0; }

.ant-picker-input > input:hover {
  background-color: #fff0;
  border-color: #ff10; }

.ant-picker-suffix {
  color: white; }

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff0; }

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff0; }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff0;
  border: 0;
  border-bottom: 1px solid #fff;
  color: #ffffff; }

.ant-input-affix-wrapper {
  background-color: #fff0;
  border: 0;
  border-bottom: 1px solid white; }

.p-1 {
  padding: 4px; }

.p-2 {
  padding: 8px; }

.p-4 {
  padding: 16px; }

.p-8 {
  padding: 32px; }

.p-12 {
  padding: 48px; }

.p-16 {
  padding: 64px; }

.mb-1 {
  margin-bottom: 4px; }

.mb-2 {
  margin-bottom: 8px; }

.mb-4 {
  margin-bottom: 16px; }

.mb-8 {
  margin-bottom: 32px; }

.mb-16 {
  margin-bottom: 64px; }

.mt-1 {
  margin-top: 4px; }

.mt-2 {
  margin-top: 8px; }

.mt-4 {
  margin-top: 16px; }

.mt-8 {
  margin-top: 32px; }

.mt-16 {
  margin-top: 64px; }

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); }

.text-rigt {
  text-align: right; }

.page-subtitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 170%;
  /* or 31px */
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff; }
  .page-subtitle.yellow {
    color: #e1bf40; }

.loaderSpacer {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px; }

button.ant-btn.ant-btn-primary {
  border-color: #ffc107;
  background: #ffc107; }

.ant-spin-text {
  color: #e1bf40; }

.ant-spin-dot-item {
  background-color: #e1bf40; }
