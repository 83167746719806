.profile-container {
  max-width: 800px;
}

.Profile {
  .margin-left-style {
    margin-left: 25%;

    @media (max-width: 580px) {
      margin-left: 0%;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #faad14;
    border-color: #faad14;
    border-radius: 4px;
    height: 20px;
    width: 20px;
  }

  .button.ant-btn.ant-btn-yellow {
    width: '50%';

    @media (max-width: 400px) {
      margin-left: 0;
    }
  }
}

.profileform-p {
  color: white;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.04em;
}