@import "../../styles/elements";
.oneoffconfirmation-confirmation {
    margin-top: 66px;

    .c-page-heading {
        text-align: center;
        font-size: 22px;
        margin-bottom: 2em;
    }
    .spinner-style {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 25%;
    }
    .c-line {
        background: $color-theme-yellow;
        height: 1px;
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .c-description-container {
        white-space: pre-wrap;
        margin: auto;
        font-family: Inter;
        font-size: 16px;
        text-align: center;
        max-width: 54ch;
        padding: 2.5em;
        background-color: rgba(0, 0, 0, 0.4);
        border: 0.5px solid #4285F4;
        border-radius: 10px;
        margin-bottom: 2em;
    }

    .c-footer {
        margin: auto;
        font-family: Inter;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
        margin-left: 25%;
    }

    .custom-footer {
        margin: auto;
        font-family: Inter;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
        text-align: center;
    }

    .c-submit {
        max-width: 30rem;
        margin: auto;
        padding: 1.5em;
        border-radius: 1em;
        background-color: black;
    }

    .c-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: #FFFFFF;
    }

    .c-sub-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        opacity: 0.8;
    }
    .c-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.8;
    }
    .c-price {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #FFFFFF;
    }

    .c-brief {
        font-family: Inter;
        text-align: justify;
        letter-spacing: 0.04em;
        font-size: 16px;
    }
    .c-buy-now {
        margin-top: 40px;
    }
    .c-buy-button {
        width: 80%;
        color: black;
        font-size: 16px;
        padding: 1em;
        border-radius: 1em;
        background-color: $color-theme-yellow;
        display: block;
        margin: auto;
        text-align: center;
        border: $color-theme-yellow;
    }

}
