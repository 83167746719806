@import "../../styles/elements";


.payment-card-block {
    border: 1px solid $color-theme-yellow;
    padding: 15px;
    border-radius: 10px;
    height: 130px;
    font-size: 22px;
    margin-bottom: 15px;
    max-width: 400px;
    cursor: pointer;

    &:hover,
    &.active {
        border: 3px solid $color-theme-yellow;
    }
}

.button-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.add-plus-icon {
    margin-right: 10px;
    margin-bottom: 2px;
}