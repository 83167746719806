.gemstone-enquiry-confirmation {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;

    div {
        width: 700px;
        max-width: 90%;
        margin: 0 16px;
        background: linear-gradient(98deg, rgba(35, 10, 55, 0.85) -9.75%, rgba(11, 3, 17, 0.85) 163.31%);
        border-radius: 10px;
        padding: 48px;

        p {
            margin-bottom: 30px;
            color: #FFF;
            text-align: center;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        button {
            max-width: 350px;
        }

        @media only screen and (max-width: 575px) {
            padding: 24px;

            p {
                display: block;
            }
        }
    }
}