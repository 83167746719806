@import "../../styles/elements";

.button-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.add-plus-icon {
    margin-right: 10px;
    margin-bottom: 2px;
}