@import "../../styles/elements";

.payment-plan-select-page {
    .main-content {
        max-width: 1100px;
    }
}

.payment-plans-row {
    justify-content: center;
    align-items: center;

    .payment-plan-col {
        justify-content: center;
        align-items: center;
        display: flex;
    }
}

.center-align {
    text-align: center;
}

.sub-title-text {
    color: #ffffff;
    text-align: center;
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: 24px;
}

.cursor-pointer {
    cursor: pointer;
}

.description {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    color: white;
    width: 400px;
    margin-left: 25%;
    text-align: center;
    letter-spacing: 0.04em;

    @media (max-width: 580px) {
        width: 100%;
        margin-left: 0;
    }
}

button.ant-btn.ant-btn-yellow {
    background: rgb(225, 191, 64);
    border-radius: 10px;
    border-color: rgb(225, 191, 64);
    width: 50%;
    color: rgb(0, 0, 0);
    height: 64px;
    margin-top: 60px;
    font-family: Inter;
    font-weight: 600;
    font-size: 20px;

    @media (max-width: 580px) {

        width: 100%;
    }

    &.full {
        width: 100%;
    }
}

.text-small {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    letter-spacing: 0.06em;
    text-decoration-line: underline;
    color: $color-theme-yellow;
}

.moonsign-images {
    position: relative;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.ant-image-img {
    margin-top: 12px;
}

.subscription-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 15px 0px 15px;
    ;
    width: 100%;
    max-width: 344px;
    height: 275px;
    background: linear-gradient(180deg, rgba(73, 55, 96, 0.2) 0%, rgba(27, 8, 45, 0.2) 100%);
    box-shadow: inset -3px -5px 4px rgba(225, 191, 64, 0.19), inset 5px 5px 4px rgba(164, 118, 223, 0.08);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    align-items: center;
    margin-bottom: 30px;

    &.active {
        height: 294px;
        margin-top: -10px;
        box-shadow: inset -4px -4px 4px rgba(225, 191, 64, 1), inset 4px 4px 4px rgba(225, 191, 64, 1);
    }

    .subscription-box-button {
        bottom: 0;
        margin-bottom: 30px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.subscription-box-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.payment-plan-img {
    max-width: 30px;
    margin-right: 15px;
}

.payment-plan-star-img {
    max-width: 22px;
    margin-left: 15px;
}

.subscription-box-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    margin-top: 30px;
}

button.ant-btn.subscription-box-button {
    max-width: 200px;
    width: 100%;
    height: 50px;
    font-size: 12px;
    background: linear-gradient(180deg, rgba(225, 191, 64, 0.3) 22.4%, rgba(238, 238, 238, 0.171) 100%);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin: 25px 25px;
    border-color: #ffffff00;
    color: #FFFFFF;
    overflow-wrap: break-word;

    &:hover {
        background: linear-gradient(180deg, rgba(225, 191, 64, 0.238) 6.77%, rgba(225, 191, 64, 0.245) 100%);
        box-shadow: inset 2px 1px 20px #000000;
        border-radius: 16px;
        border-color: #ffffff00;
    }
}

.horoscopeGenerated {
    p.description.text-long {
        @media (max-width: 580px) {
            margin-left: 10px;
            width: 325px;
        }
    }

    button.ant-btn.ant-btn-primary.ant-btn-yellow {
        margin-left: 0%;
    }
}

.moonsignSelect {
    .title-text {
        margin-top: 100px !important;
    }

    .selected-moonsign {
        width: 100px;
        height: 100px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 30px rgba(225, 191, 64, 0.32);
        border-radius: 10px;
        border: 3px solid #E1BF40;
    }
}

.reveal-moonsign-modal {
    .ant-modal-content {
        background-color: transparent !important;
        margin-top: -30vh;

        .reveal-moonsign-modal-content {
            background-color: #000;
            border-radius: 15px;
            text-align: center;
        }

        .reveal-modal-title {
            text-align: center;
            color: #fff;
            font-size: 22px;
            font-weight: bold;
            padding: 30px;
        }

        .reveal-moonsign-modal-button {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            max-width: 400px;
            bottom: 120px;
        }
    }
}

.coupon-box {
    width: 50%;
    margin-left: 25%;
    margin-top: 50px;
}

.coupon-text-box {
    margin-bottom: 15px;
    text-align: left;
}

.coupon-box-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.04em;
    color: #E1BF40;
}

.coupon-box-desc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 170%;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.min-button-width-height {
    max-width: 318px;
    max-height: 64px;
}