@import "../../styles/elements";

.noticeboard-detail {
    margin-top: 66px;

    .c-mark-all-read {
        color: $color-theme-yellow;
        text-align: right;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.38px;
        text-decoration-line: underline;
        float: right;
        cursor: pointer;
    }

    .c-page-heading {
        color: $color-white;
        text-align: center;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .noticeboard-heading {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .c-mark-all-read-head {
        position: absolute;
        padding: 0px 20px;
        max-width: 800px;
        width: 100%;
    }

    .c-box-link {
        color: $color-theme-yellow;
        text-decoration: underline;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .c-box {
        border-radius: 10px;
        opacity: 0.85;
        background: linear-gradient(135deg, #230A37 0%, #0B0311 99.53%);
        flex-direction: row;
        display: flex;
        padding: 20px;
        margin-bottom: 20px;
        min-height: 150px;
    }

    .c-box-highlighted {
        border: 3px solid #DCBB4B;
    }

    .dot-icon {
        width: 5%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .c-box-content {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .c-box-title {
        color: #DCBB4B;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        padding-bottom: 10px;
    }

    .c-box-subtitle {
        color: #FFF;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        /* 133.333% */
        padding-bottom: 20px;
    }

    .c-date {
        color: rgba(255, 255, 255, 0.60);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        /* 23.8px */
        letter-spacing: 0.56px;
    }

    .c-image-box img {
        max-width: 5rem;
        margin-bottom: 2em;
    }

    .c-description {
        white-space: pre-wrap;
        margin: auto;
        font-family: Inter;
        font-size: 16px;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        background-color: black;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
    }

    .c-buy-now {
        max-width: 30rem;
        margin: auto;
        padding: 1.5em;
        border-radius: 1em;
        background-color: black;
    }

    .c-title {
        font-family: Inter;
        letter-spacing: 0.04em;
        font-size: 18px;
        color: $color-theme-yellow;
        display: block;
        margin-bottom: 0.5em;
        text-align: center;
    }

    .c-brief {
        font-family: Inter;
        text-align: justify;
        letter-spacing: 0.04em;
        font-size: 16px;
    }

    .c-buy-button {
        width: 70%;
        color: black;
        font-size: 16px;
        padding: 1em;
        border-radius: 1em;
        background-color: $color-theme-yellow;
        display: block;
        margin: auto;
        text-align: center;
    }
}

.loaderSpacer {
    margin-top: 25% !important;
}