@import "../../styles/elements";

.ant-btn-invert {
    width: '90%';
    background-color: '#ffffff00';
    border-width: 1;
    border-color: '#e1bf40';
    align-items: 'center';
}

button.submit-btn-yellow{
    background: rgb(225, 191, 64);
    border-radius: 10px;
    border-color: rgb(225, 191, 64);
    width: 50%;
    color: rgb(0, 0, 0);
    height: 60px;
    margin-top: 60px;
    font-family: Inter;
    font-weight: 600;
    @media (max-width: 580px) {
        margin-left: 10%;
        width: 75%;
        font-size: 12px;
    }

    &.full {
        width: 100%;
    }
}
