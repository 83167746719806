.display-life-event {

    nav {
        margin-top: 10px;
        width: 100%;
        min-height: 43px;
        background: linear-gradient(98deg, #230A37 -9.75%, #0B0311 163.31%);
        border-radius: 10px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        div {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 100%;

            span {
                margin: 10px;
                color: #FFF;
                font-family: Inter, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
            }
        }
    }

    h1 {
        margin: 15px 0;
    }

    p {
        margin-bottom: 25px;
    }

    .content-wrapper {
        width: 100%;
        border-radius: 10px;
        background: linear-gradient(98deg, rgba(35, 10, 55, 0.85) -9.75%, rgba(11, 3, 17, 0.85) 163.31%);
        padding: 25px;

        .video-block {
            display: flex;
            align-items: center;
            margin-top: 0px;
            position: relative;
            margin-bottom: 25px;

            iframe {
                width: 100%;
                height: 425px;
                // max-width: 550px;
                margin: 0 auto;
                justify-self: center;
            }
        }

        @media screen and (min-width: 720px) and (max-width: 800px) {
            .video-block {
                display: flex;

                iframe {
                    width: 100%;
                    height: 410px;
                    margin: 0 auto;
                    justify-self: center;
                }
            }
        }

        @media screen and (min-width: 800px) and (max-width: 950px) {
            .video-block {
                display: flex;

                iframe {
                    width: 100%;
                    height: 430px;
                    margin: 0 auto;
                    justify-self: center;
                }
            }
        }

        @media screen and (max-width: 512px) {
            .video-block {
                display: flex;

                iframe {
                    width: 100%;
                    height: 200px;
                    margin: 0 auto;
                    justify-self: center;
                }
            }
        }

        div {
            color: #FFF;
            text-align: justify;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: 0.32px;
            width: 100%;
            white-space: pre-line;
        }
    }

    @media screen and (max-width: 575px) {
        nav {
            display: none
        }

        .content-wrapper {
            padding: 18px;
        }
    }

}