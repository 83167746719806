.gemstone-container {

    .title {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
    }

    .content-wrapper {
        max-width: 700px;
        border-radius: 10px;
        background: linear-gradient(98deg,
                rgba(35, 10, 55, 0.85) -9.75%,
                rgba(11, 3, 17, 0.85) 163.31%);
        padding: 25px;

        .media-wrapper {

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                margin-bottom: 20px;
                border-radius: 5px;
            }

            .video-block {
                width: 100%;
                margin-bottom: 20px;

                .video-iframe {
                    width: 100%;
                }
            }


            .thumbnails-wrapper {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
                gap: 20px;

                img {
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    cursor: pointer;
                    border-radius: 4px;
                    margin-bottom: 0;
                }

                .video-thumb-wrapper {
                    position: relative;

                    .video-thumbnail {
                        width: 80px;
                        height: 80px;
                    }

                    .play-video-icon {
                        position: absolute;
                        top: 20px;
                        left: 20px;
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 4px;
                        margin-bottom: 0;
                    }
                }
            }
        }



        .gemstone-description {
            color: #FFF;
            text-align: justify;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            white-space: pre-line;
            margin-bottom: 40px;
        }
    }
}