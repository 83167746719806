@import "../../../styles/elements";

.right-menu-toggle-icon {
    width: 21px;
    height: 20px;
    background-color: $color-theme-yellow;
    -webkit-mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    @include transition(all 0.3s ease-in-out);
}

.right-menu-toggle-button {
    background: transparent !important;
    border: none !important;
    text-align: right !important;
    justify-content: flex-end;
    display: flex !important;
    width: 100%;

    &:hover {
        background: transparent;
        .right-menu-toggle-icon {
            background-color: white;
        }
    }
}

.right-menu-drawer {
    font-family: Inter;

    p, h1, h2, h3, h4, h5, h6 {
        font-family: Inter;
    }
    .ant-drawer-body {
        background: linear-gradient(346.98deg, rgba(0, 0, 0, 0.9) -22.9%, rgba(0, 0, 0, 0.9) 21.42%, rgba(225, 191, 64, 0.9) 114.09%);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;


        .right-menu-toggle-icon {
            background-color: $color-white;
        }

        .right-menu-toggle-button {
            &:hover {
                .right-menu-toggle-icon {
                    background-color: $color-theme-yellow;
                }
            }
        }

        .menu__footer {
            align-self: self-end;
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-bottom: 31px;

        }

    }

    .logo-block {
        text-align: center;
        margin-top: 70px;

        .logo-title {
            font-size: 18px;
            margin-top: 22px;
            color: $color-theme-yellow;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 9px;
            line-height: 170%;
            /* or 15px */
            text-align: center;
            letter-spacing: 0.04em;
            color: #FFFFFF;
        }
        img {
            max-width: 120px;
        }
    }

    .capter-menu {
        margin: 55px auto 0px auto;
        padding-left: 0px;
        font-size: 14px;
        list-style: none;
        max-width: 200px;
        position: relative;

        button {
            background-color: transparent;
            border: none;
            text-decoration: underline;
            text-align: left;

        }

        li {
            margin-bottom: 20px;
            padding-left: 40px;
            &:before {
                display: block;
                content: "";
                width: 21px;
                height: 20px;
                background-color: $color-white;
                -webkit-mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
                mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
                position: absolute;
                margin-left: -40px;
                margin-top: 2px;
            }

            a {
                color: $color-white;
            }

            &:hover, &.active {
                a, button {
                    color: $color-theme-yellow;
                    text-decoration: none;

                }

                &:before {
                    background-color: $color-theme-yellow;
                }
            }
        }
    }

    @media screen and (max-width: 350px) {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }

}

.menu__footer {
    bottom: 55px;
    width: 100%;

    .bottom-sidebar-link {
        text-align: center;
        margin-top: 25px;
    }
}
