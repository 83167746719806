@import "../../styles/elements";

.my-subscriptions-content {
    align-items: center;
    display: flex;
    flex-direction: column;

    .page-subtitle {
        margin-bottom: 40px;
    }


    .single-subscription {
        border-radius: 10px;
        min-height: 220px;
        max-width: 420px;
        width: 100%;
        margin-bottom: 40px;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
        padding: 20px;

        h3 {
            width: 100%;
            text-align: center;
            color: $color-theme-yellow;
            font-size: 22px;
        }

        .package-title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 170%;
            /* or 31px */
            letter-spacing: 0.04em;
            color: $color-white;
            margin-bottom: 0px;
            margin-bottom: 16px;
        }

        .package-subtitle {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 170%;
            /* or 24px */
            letter-spacing: 0.04em;
            color: $color-white;
        }

        .left-col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            min-height: 220px;

            img {
                max-width: 100px;
                width: 100%;
            }
        }

        .right-col {
            display: flex;
            align-items: center;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            /* or 24px */
            text-align: justify;
            letter-spacing: 0.04em;
        }

        &.active {
            background: linear-gradient(138.27deg, rgba(0, 0, 0, 0.5) 22.13%, rgba(123, 123, 123, 0.5) 109.22%);
            @include box-shadow(0px 0px 15px 5px rgba(225, 191, 64, 0.2));

            .package-title {
                color: $color-theme-yellow;
                // margin-bottom: 0px;
            }
        }
    }

    .modal-content {
        background-color: #000;
        border-radius: 15px;
        text-align: center;
    }

    .msg-modal {
        .ant-modal-content {
            background-color: transparent !important;
            margin-top: -30vh;

            .reveal-modal-content {
                background-color: #000;
                border-radius: 15px;
                text-align: center;
            }

            .reveal-modal-title {
                text-align: center;
                color: #fff;
                font-size: 22px;
                font-weight: bold;
                padding: 30px;
            }

            .reveal-modal-button {
                position: fixed;
                left: 50%;
                transform: translateX(-50%);
                max-width: 400px;
                bottom: 120px;
            }
        }
    }
}

.ant-modal-content {
    border-radius: 10px !important;
}

button.ant-btn.ant-btn-default {
    border-radius: 10px !important;
}

button.ant-btn.ant-btn-primary {
    border-radius: 10px !important;
}

.ant-modal-confirm-content {
    white-space: pre-line !important;
}