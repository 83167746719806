@import "../../styles/elements";

.theme-yellow-button {
    background-color: $color-theme-yellow;
    color: #000;
    border-radius: 15px;
    border: 1px solid $color-theme-yellow;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: 60px;
    width: 100%;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.38px;
}