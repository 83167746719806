@import "../../../styles/elements";

.loginform-wrap {
    display: flex;
    min-height: 100vh; // 89.8vh;
    font-size: 12px;
    text-align: center;
    align-items: center;

    .ant-form-vertical .ant-form-item {
        margin-bottom: 24px;

        &.password-input-row {
            margin-bottom: 10px;
        }
    }

}

.loginform-container {
    max-width: 592px;
    width: 100%;
    margin: 0 auto;
    padding: 87px 80px 87px 80px;
    background: rgba(0, 0, 0, 0.5);
    height: auto;
    // margin-top: -15vh;

    h1 {
        color: $color-white;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 48px;
    }

    .form-inner-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .signup-link-row {
        margin-top: 25px;
    }

    .ant-btn-green {
        width: 100%;
        height: 63px;
        background: #5CED73;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        border: none;

        &:hover {
            background-color: #5CED73;
            color: '#000000'
        }

        .logo-image {
            height: 200px;
            width: 200px;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 40px 40px 40px 40px;

        .form-inner-wrapper {
            padding-left: 0px;
            padding-right: 0px;
        }

        .logo-image {
            height: 100px;
            width: 100px;
        }
    }
}

button.ant-btn.ant-btn-primary.ant-btn-yellow.full {
    @media (max-width: 580px) {
        margin-left: 0%;
    }
}