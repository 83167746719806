.header {
  z-index: 1; }
  .header.floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  .header .header-logo-text {
    font-family: 'Italiana', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #FFFFFF; }
  .header .header__inner {
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    color: #ffffff;
    padding: 0 16px;
    height: 66px; }
    .header .header__inner .middle-col {
      text-align: center; }
    .header .header__inner .header__title {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: inherit;
      text-transform: capitalize; }
    .header .header__inner .backlink {
      text-decoration: none;
      padding-left: 35px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      /* or 24px */
      letter-spacing: 0.04em;
      cursor: pointer; }
      @media (max-width: 450px) {
        .header .header__inner .backlink {
          font-size: 10px; } }
      .header .header__inner .backlink:before {
        width: 21px;
        height: 20px;
        background-color: #e1bf40;
        -webkit-mask: url("../../../assets/images/left-angle.svg") no-repeat center;
        mask: url("../../../assets/images/left-angle.svg") no-repeat center;
        content: "";
        position: absolute;
        margin-left: -35px;
        margin-top: 3px;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
      .header .header__inner .backlink img {
        padding-right: 20px; }
      .header .header__inner .backlink:hover {
        color: #ffffff; }
        .header .header__inner .backlink:hover:before {
          background-color: #ffffff; }
    .header .header__inner .ant-row-flex .ant-col:first-child > :first-child {
      margin-right: 10px; }
  .header .icon {
    fill: currentColor; }
  .header .icon-menu {
    font-size: 48px;
    margin-right: 6px; }
  .header .icon-bell {
    font-size: 19px; }
  .header .icon-new-order {
    font-size: 16px; }
  .header .toolbar-button {
    background: transparent;
    border: none; }
    .header .toolbar-button:hover {
      background: transparent;
      border: none; }
  .header .right-menu-placeholder {
    width: 51px; }
  .header .header-custom-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff; }
    @media (max-width: 450px) {
      .header .header-custom-title {
        font-size: 16px; } }
