.gemstone-enquiry-container {

    .form-container {
        width: 100%;
        border-radius: 10px;
        background: linear-gradient(98deg,
                rgba(35, 10, 55, 0.85) -9.75%,
                rgba(11, 3, 17, 0.85) 163.31%);
        padding: 25px;
        display: flex;
        flex-direction: column;

        .form-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 30px;

            label {
                color: #FFF;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 170%;
                letter-spacing: 0.72px;
                text-align: left;
            }

            input {
                background: transparent;
                border: none;
                border-bottom: 2px solid #FFF;
                color: #FFF;
                font-family: Nunito;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                padding: 10px;
            }

            textarea {
                background: transparent;
                border: 1px solid #FFF;
                border-radius: 4px;
                min-height: 130px;
                color: #FFF;
                font-family: Nunito;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                padding: 10px;
                resize: none;
            }

            .error {
                margin-top: 10px;
                text-align: left;
                color: #E1BF40;
            }
        }

        .caption-content {
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            span,
            a {
                text-align: left;
            }

            .caption-question {
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 170%;
                letter-spacing: 0.64px;
            }

            .caption-tel {
                color: #FFF;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 170%;
                letter-spacing: 0.64px;
                text-decoration-line: underline;
            }
        }

        .submit-button {
            width: 350px;
            max-width: 90%;
            height: 60px;
            border-radius: 15px;
            background: #E1BF40;
            border: none;
            color: #000;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.38px;
            text-transform: capitalize;
            margin-bottom: 0;
            cursor: pointer;
        }
    }
}