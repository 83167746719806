@import "../../../styles/elements";

.payment-form-content-wrapper {
    margin-top: 100px;
    max-width: 320px;

    label {
        font-family: Inter !important;
        font-size: 16px;
    }
}

.ant-input {
    &.payment-input {
        background: linear-gradient(180deg, rgba(255, 179, 0, 0.24) 0%, rgba(255, 255, 255, 0.24) 100%);
        border-bottom: none;
        border-radius: 10px;
        padding: 10px;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-placeholder;
            opacity: 1;
            /* Firefox */
            font-family: Inter;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.02em;
            font-style: normal;
        }

        &:focus {
            border-color: $color-theme-yellow;
            box-shadow: 0 0 0 2px rgba(225, 191, 64, 0.2);
        }
    }
}