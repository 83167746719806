@import "../../styles/elements";

.order-confirm-details-block {
    margin-top: 140px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
}

.back-link {
    margin-top: 15px;
    display: block;
}

.horoscope-selected-icon {
    max-width: 18px;
    margin-right: 15px;
}
