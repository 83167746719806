@import "../../styles/elements";

.ant-tabs {

    &.theme-tabs {

        .ant-tabs-nav-list {
            flex: 1;
            align-items: center;
            justify-content: center;
        }

        .ant-tabs-nav {
            &:before {
                border: none;
            }
        }

        .ant-tabs-tab {
            width: 100%;
            max-width: 200px;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            align-items: center;
            justify-content: center;
            color: $color-white;

            &:first-child {
                border-radius: 10px 0px 0px 10px;
            }

            &:nth-child(3) {
                border-radius: 0px 10px 10px 0px;
            }

            &.ant-tabs-tab-active {
                background-color: $color-theme-yellow;
                border-bottom: none;
                color: $color-black;

                .ant-tabs-tab-btn {
                    color: $color-black;
                }

            }
        }

        .ant-tabs-content {
            color: $color-white;
        }

        .ant-tabs-ink-bar {
            display: none;
        }

        .ant-tabs-tab+.ant-tabs-tab {
            margin: 0 0 0 0;
            border-left: 1px solid $color-border-grey;

        }
    }

}

.dashboard-container {
    max-width: 800px;
    margin: 0 auto;
}

.content {
    margin-top: 75px;
}

.horoscope-list {
    margin-top: 20px;

    .horoscope-item {
        border-radius: 1em;
        background-color: rgba(0, 0, 0, 0.5);
        margin-bottom: 20px;
        min-height: 250px;
        padding: 24px;
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: auto auto 60px;
        gap: 24px 24px;
        grid-template-areas:
            "moonsign image"
            "description image"
            "buttons buttons";


        .buttons {
            grid-area: buttons;
            justify-self: stretch;
            display: flex;
            justify-content: space-between;

            button {
                width: calc(50% - 24px);
                transition: all 0.3s ease-in-out;

                &:hover {
                    transform: scale(1.03);
                }
            }

            .subscribed-button-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .subscribed-button {
                width: 55%;
            }

            @media only screen and (max-width: 575px) {
                button {
                    width: calc(50% - 6px);
                }

                .subscribed-button {
                    width: 100%;
                }
            }
        }

        .moonsign {
            grid-area: moonsign;
        }

        .horoscope-description {
            grid-area: description;
            justify-self: stretch;

            p {
                font-weight: 300;
                font-size: 16px;
                font-family: 'Nunito', sans-serif;
                line-height: 1.6;
            }
        }

        .horoscope-image {
            grid-area: image;
        }

        img {
            max-width: 150px;
            width: 100%;
        }

        .left-col {
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .right-col {
            padding: 30px 30px 30px 0px;
        }

        .actions {
            text-align: center;
        }

        .horoscope-title {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.04em;
            color: $color-theme-yellow;
            margin-bottom: 6px;
        }

        .horoscope-subtitle {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: $color-white;
            font-weight: 400;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            text-align: justify;
            letter-spacing: 0.04em;
        }

        @media only screen and (max-width: 575px) {
            .left-col {
                padding-top: 20px;
            }

            .right-col {
                padding: 20px 20px 20px 20px;
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .horoscope-item {
            grid-template-columns: 3fr 1fr;
            grid-template-rows: auto auto 60px;
            gap: 16px 16px;
            grid-template-areas:
                "moonsign image"
                "description description"
                "buttons buttons";
        }
    }

    .user-subscribed {
        border: 4px solid $color-theme-yellow;
    }
}

.signup-button {
    position: fixed;
    top: 0;
    right: -30px;
    z-index: 999;
    width: 50%;
    max-width: 450px;
    display: flex;
    flex-direction: row;
}

.bell-icon {
    margin-right: 50px;
    cursor: pointer;
    padding: 15px;
    width: 25%;
    text-align: left;
}