.counterBlock {
    .counterTimeBlock {
        display: inline-block;
        margin-left: 2px;
        margin-right: 2px;
        width: 50px;
    }
    .counterTimeNumberBlock {
        background-color: #e1bf40;
        width: 40px;
        height: 40px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        display: block;
        margin: 0 auto;
    }

    .counterTimeLabelBlock {
        font-size: 12px;
    }
}
