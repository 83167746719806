@import "../../styles/elements";

.consultation-form {
    margin-top: 66px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    padding: 1.5em;

    .cform-heading {
        text-align: center;
        margin-bottom: 2em;
    }

    .cform-sub-heading {
        font-size: 18px;
        color: $color-theme-yellow;
        padding-left: 1em;
    }

    .cform-sub-strap {
        font-size: 14px;
        padding-left: 1.3em;
    }

    .cform-questions {
        max-width: 40rem;
        margin: 2em auto;
        display: flex;
        flex-direction: column;
        background-color: black;
        padding: 1.5em;
        border-radius: 1em;
    }

    .cform-question {
        display: flex;
        margin-bottom: 2em;
        align-items: center;
    }

    .cform-checkbox {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        margin-right: 1em;
        accent-color: $color-theme-yellow;
    }
    .cform-phone-number {
        margin-top: 3em;
    }
    .cform-other-questions-container {
        margin-top: 3em;
    }
    .cform-phone-number-container {
        background-color: black;
        height: 50px;
        width: 467px;
        margin: 2em auto;
    }
    cform-phone-number-input{
        color: black;
        display: block;
        margin: auto;
        padding: 1em;
        height: 50px;
        width: 467px;
        margin-top: 2em;
    }
    .cform-other-questions-input {
        color: black;
        display: block;
        margin: auto;
        border-radius: 1em;
        padding: 1em;
        height: 10rem;
        width: 25rem;
        max-width: 90%;
    }

    .cform-other-questions-input:focus {
        outline-color: $color-theme-yellow;
    }

    .cform-continue {
        display: block;
        background-color: $color-theme-yellow;
        color: black;
        border-radius: 1em;
        max-width: 90%;
        width: 25rem;
        height: 4rem;
        margin: 3em auto 1em auto;
    }
}