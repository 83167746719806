@import "../../styles/elements";
.consultation-confirmation {
    margin-top: 66px;

    .c-page-heading {
        text-align: center;
        font-size: 22px;
        margin-bottom: 2em;
    }


    .c-description {
        white-space: pre-wrap;
        margin: auto;
        font-family: Inter;
        font-size: 16px;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        background-color: black;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
    }

    .c-footer {
        margin: auto;
        font-family: Inter;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
        margin-left: 25%;
    }

    .custom-footer {
        margin: auto;
        font-family: Inter;
        text-align: justify;
        max-width: 70ch;
        padding: 1.5em;
        border-radius: 1em;
        letter-spacing: 0.04em;
        margin-bottom: 2em;
        text-align: center;
    }

    .c-submit {
        max-width: 30rem;
        margin: auto;
        padding: 1.5em;
        border-radius: 1em;
        background-color: black;
    }

    .c-title {
        font-family: Inter;
        letter-spacing: 0.04em;
        font-size: 18px;
        color: $color-theme-yellow;
        display: block;
        margin-bottom: 0.5em;
        text-align: center;
    }

    .c-brief {
        font-family: Inter;
        text-align: justify;
        letter-spacing: 0.04em;
        font-size: 16px;
    }

    .c-buy-button {
        width: 70%;
        color: black;
        font-size: 16px;
        padding: 1em;
        border-radius: 1em;
        background-color: $color-theme-yellow;
        display: block;
        margin: auto;
        text-align: center;
    }

}
