.gemstone-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    .gemstone-image {
        position: relative;

        button {
            position: absolute;
            top: 231px;
            right: 20px;
            width: 260px;
            height: 45px;
            border-radius: 5px;
            background: #E1BF40;
            border: none;
            color: #230A37;
            text-align: center;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.38px;
            text-transform: capitalize;
        }
    }

    img {
        width: 300px;
        height: 300px;
        opacity: 1;
        object-fit: cover;
        border-radius: 5px 5px 0px 0px;
    }

    p {
        text-align: center;
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 200%;
        margin-bottom: 0;
        padding: 12px;
        opacity: 0.85;
        background: linear-gradient(98deg, #230A37 -9.75%, #0B0311 163.31%);
        border-radius: 0px 0px 5px 5px;
    }
}