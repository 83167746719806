@import "../../../styles/elements";


.left-menu-toggle-icon {
    width: 21px;
    height: 20px;
    background-color: $color-theme-yellow;
    -webkit-mask: url('../../../assets/images/left_menu_toggle_icon.svg') no-repeat center;
    mask: url('../../../assets/images/left_menu_toggle_icon.svg') no-repeat center;
    @include transition(all 0.3s ease-in-out);
}

.left-menu-toggle-button {
    background: transparent !important;
    border: none !important;

    &:hover {
        background: transparent;
        .left-menu-toggle-icon {
            background-color: white;
        }
    }
}

.left-menu-drawer {
    .ant-drawer-body {
        background: linear-gradient(346.98deg, rgba(0, 0, 0, 0.9) -22.9%, rgba(0, 0, 0, 0.9) 21.42%, rgba(225, 191, 64, 0.9) 114.09%);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;


        .left-menu-toggle-icon {
            background-color: $color-white;
        }

        .left-menu-toggle-button {
            &:hover {
                .left-menu-toggle-icon {
                    background-color: $color-theme-yellow;
                }
            }
        }

        .menu__content{
            overflow: auto;
            margin-bottom: 10px;
        }

        .menu__footer {
            align-self: self-end;
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-bottom: 31px;

        }

    }

    @media screen and (max-width: 350px) {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }
}

.side-menu-logo-title {
    text-align: center;
    font-family: Italiana;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: $color-white;
    margin-top: 42px;
    margin-bottom: 0px;
}

.menu__content {
    margin-top: 55px;
    position: relative;
    overflow: hidden;

    .menu__nav {
        list-style: none;
        margin-left: 0px;
        padding-left: 0px;

        li {
            margin-bottom: 15px;
            text-align: center;
        }
    }
}

.ant-btn {
    &.sidebar-btn {
        width: 100%;
        height: 63px;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        position: relative;
        background-color: rgba(0, 0, 0, 0.6);
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 170%;
        letter-spacing: 0.04em;
        color: $color-white;
        text-decoration: none;
        border-radius: 8px;
        border: none;
        padding-left: 36px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
            color: $color-white;
        }
        img {
            margin-left: auto;
        }
    }
}
.menu__footer {
    bottom: 55px;
    width: 100%;

    .bottom-sidebar-link {
        text-align: center;
        margin-top: 8px;
    }
}
.live-status-img {
    width: 65px;
    margin-left: 15px;
}
.menu {
    flex-direction: row;
    display: flex;
}
.rounded-button {
    width: 80px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 10px;
}
.rounded-button-active {
    background: #EF000C !important;
}
.parent-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 0px;
}
.circle-wrapper {
    justify-content: center;
    width: 25%;
    text-align: center;
    margin-right: 7px;
}
.inner-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    background: #8A8A8A;
}
.inner-circle-active {
    background: #FFFFFF !important;
} 
.rounded-button-inner-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.38px;
    text-transform: capitalize;
    color: #8A8A8A;
    text-decoration: none;
}
.rounded-button-inner-text-active {
    color: #FFFFFF !important;
} 
.ahref-style {
    text-decoration: none;
}
@media screen and (max-width: 380px) {
    .rounded-button {
        width: 60px;
        height: 28px;
    }
    .inner-circle {
        width: 12px;
        height: 12px;
    }
    .rounded-button-inner-text {
        font-size: 13px;
    }
    .parent-body {
        padding-left: 20px;
        padding-right: 20px;
    }
    .circle-wrapper {
        margin-right: 10px;
    }
}