.container {
    padding: 20px 60px;
    max-width: 1800px;
    margin: 0 auto;

    h1 {
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 25px;
    }

    .input-wrapper {
        position: relative;
        width: 650px;
        height: 45px;
        margin: 0 auto;
        margin-bottom: 10px;

        img {
            z-index: 2;
            position: absolute;
            top: 15px;
            left: 12px;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 650px;
            height: 45px;
            align-items: center;
            flex-shrink: 0;
            border-radius: 5px;
            border: 0.5px solid #E1BF40;
            opacity: 0.9;
            background: #230A37;
            margin: 0 auto;
            padding-left: 36px;
            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }
    }

    p {
        width: 100%;
        text-align: center;
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        letter-spacing: 0.56px;
        margin-bottom: 25px;
    }

    .gemstone-display {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
        gap: 60px;
        max-width: 1400px;
        margin: 0 auto;
    }
}

.loading-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: red;
}