.main-content {
  max-width: 800px;
  padding: 20px;
  margin: 0 auto; }

.page-wrap {
  width: 100%; }
  .page-wrap.login {
    height: 100vh;
    overflow: hidden; }
    @media screen and (max-width: 600px) {
      .page-wrap.login {
        overflow: visible; } }
