.languageSelectForm {
    float: right;
    width: 100px;

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        box-shadow: none;
    }

    .ant-select:hover{

    }
}
