@import "../../../styles/elements";

.autocomplete-dropdown-container {

    background: linear-gradient(130.89deg, #ffbc03 -10.99%, #3c3c3c 58.57%);
    border-radius: 15px;

    .location-item:first-child {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .location-item:last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}
