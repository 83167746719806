@import "../../styles/elements";

.purchase-life-events {
    h1 {
        font-size: 28px;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        margin: 24px 0;
    }

    .life-events-description {
        line-height: 24px;
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 12px;
    }

    @media only screen and (max-width: 575px) {
        .life-events-description {
            display: none;
        }
    }
}

.content-container {
    display: flex;
    justify-content: center;

    .content-grid {
        width: 100%;
        max-width: 1400px;
        margin: 0 8%;
        display: grid;
        grid-template-columns: 1fr 0.5fr;
        grid-template-rows: 1fr;
        gap: 0px 72px;
        grid-template-areas:
            "parts-list basket";

        .parts-list {
            grid-area: parts-list;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 32px;

            ul {
                margin: 0;
                padding: 0;
                width: 100%;
                margin-top: 20px
            }

            .activated-part {
                border: 4px solid $color-theme-yellow;
            }

            .selected-part {
                border: 2px solid $color-theme-yellow;
            }

            li {
                list-style-type: none;
                margin: 0;
                width: 100%;
                margin-bottom: 20px;
                padding: 24px;
                border-radius: 10px;
                background: linear-gradient(98deg, rgba(35, 10, 55, 0.85) -9.75%, rgba(11, 3, 17, 0.85) 163.31%);
                display: flex;
                border: 2px solid transparent;

                .svg-wrapper {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    margin-right: 16px;
                }

                .list-item-content {
                    width: 100%;

                    .part-title {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 12px;

                        span {
                            color: #fff;
                            font-family: 'Inter', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 130%;
                        }
                    }

                    h3 {
                        color: $color-theme-yellow;
                        font-family: 'Inter', sans-serif;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 130%;
                        margin-bottom: 0;
                    }

                    p {
                        color: #FFF;
                        font-family: 'Nunito', sans-serif;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }

                    .view-event-btn {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        opacity: 1;

                        button {
                            max-width: 350px;
                        }
                    }

                }
            }
        }


        .basket {
            grid-area: basket;
            border-radius: 10px;
            border: 0.5px solid #DCBB4B;
            background: #230A37;
            min-width: 220px;
            padding: 30px 50px;
            height: fit-content;
            margin-bottom: 12px;
            position: sticky;
            top: 8%;

            h3 {
                color: #FFF;
                text-align: center;
                font-family: 'Inter', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
            }

            p {
                color: #FFF;
                font-family: Nunito, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                margin-bottom: 10px;
            }

            .basket-info-wrapper {
                display: flex;
                justify-content: space-between;
            }

            ul {
                margin: 0;
                padding: 0;
                width: 100%;
            }

            li {
                border-left: 2px solid #fff;
                list-style-type: none;
                margin: 0 0 20px 24px;
                padding-left: 15px;
                color: #FFF;
                font-family: Nunito, sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
            }
        }

    }

    @media only screen and (max-width: 575px) {
        .content-grid {
            grid-template-columns: 1fr;
            gap: 0px 0px;
            grid-template-areas:
                "parts-list";
        }

        .basket {
            display: none;
        }

    }

}

.mobile-basket {
    display: none;
}

@media only screen and (max-width: 575px) {
    .mobile-basket {
        display: block;
        position: fixed;
        left: 0;
        width: 100vw;
        overflow: hidden;
        border-radius: 35px 35px 0px 0px;
        border: 0.5px solid #DCBB4B;
        background: #230A37;
        padding: 25px;
        transition: all 0.3s ease-in-out;

        h3 {
            color: #FFF;
            text-align: center;
            font-family: 'Inter', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
        }

        p {
            color: #FFF;
            font-family: Nunito, sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            margin-bottom: 10px;
        }

        .basket-info-wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

}