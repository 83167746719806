@import "../../styles/elements";

.consultations {
    margin-top: 66px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;

    .c-page-heading {
        text-align: center;
        font-size: 22px;
        margin-bottom: 2em;
    }
    
    .c-section {
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 3em;
        scroll-margin-top: 66px;
    }
    
    .c-section-title {
        margin: auto;
        max-width: 30rem;
        font-size: 18px;
        color: $color-theme-yellow;
        margin-bottom: 1.5em;
    }
    
    .c-item {
        margin: auto;
        max-width: 30rem;
        margin-bottom: 2em;
        background-color: black;
        padding: 1.5em;
        border-radius: 1em;
    }
    
    .c-item-row {
        margin-bottom: 1em;
    }
    
    .c-item-title {
        font-size: 16px;
        color: $color-theme-yellow;
        margin-bottom: 0.3em;
    }
    
    .c-item-cost {
        font-size: 16px;
        display: block;
        margin: auto;
        text-align: center;
    }
    
    .c-item-button {
        background-color: $color-theme-yellow;
        border: none;
        border-radius: 1em;
        padding: 1em 2.5em;
        color: black;
        display: block;
        margin: auto;
    }
}