@import "../../../styles/elements";

.right-menu-toggle-icon {
    width: 21px;
    height: 20px;
    background-color: $color-theme-yellow;
    -webkit-mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    @include transition(all 0.3s ease-in-out);
}

.right-menu-toggle-button {
    background: transparent !important;
    border: none !important;
    text-align: right !important;
    justify-content: flex-end;
    display: flex !important;
    width: 100%;

    &:hover {
        background: transparent;

        .right-menu-toggle-icon {
            background-color: white;
        }
    }
}

.gem-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    .title {
        padding: 0 40px;
        color: #FFF;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0.96px;
        margin-bottom: 0;
    }
}

.right-menu-drawer {
    font-family: Inter;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Inter;
    }

    .ant-drawer-body {
        background: linear-gradient(346.98deg, rgba(0, 0, 0, 0.9) -22.9%, rgba(0, 0, 0, 0.9) 21.42%, rgba(225, 191, 64, 0.9) 114.09%);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        padding: 0;

        .right-menu-toggle-icon {
            background-color: $color-white;
        }

        .right-menu-toggle-button {
            &:hover {
                .right-menu-toggle-icon {
                    background-color: $color-theme-yellow;
                }
            }
        }

    }

    .gem-right-menu-list-block {
        margin-bottom: 72px;

        .categories-list {
            padding-left: 0px;
            font-size: 14px;
            list-style: none;
            width: 100%;
            position: relative;

            li {
                width: 100%;
                cursor: pointer;
                height: 50px;

                &:hover {
                    background-color: #141414;
                }

                a {
                    color: $color-white;
                }

                &:hover,
                &.active {

                    a,
                    button {
                        color: $color-theme-yellow;
                        text-decoration: none;

                    }

                    &:before {
                        background-color: $color-theme-yellow;
                    }
                }

                button {
                    background-color: transparent;
                    border: none;
                    text-align: left;
                    width: 100%;
                    height: 100%;
                    padding: 0 40px;
                    color: #FFF;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%;

                    &:hover {
                        background-color: #141414;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 350px) {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }

}