$color-white: #ffffff;
$color-black: #000000;
$color-green: #006233;
$color-silver-sand: #BABBBC;
$color-theme-yellow: #e1bf40;
$color-placeholder: #B6B6B6;
$color-grey: #818181;
$color-border-grey: #979797;

$primary-color: $color-green;
$header-bg-color: $color-silver-sand;

@mixin transition($transition) {
  -o-transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  transition: $transition;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
