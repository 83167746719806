@import "../../../styles/elements";

.right-menu-toggle-icon {
    width: 21px;
    height: 20px;
    background-color: $color-theme-yellow;
    -webkit-mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    mask: url('../../../assets/images/right_menu_toggle_icon.svg') no-repeat center;
    @include transition(all 0.3s ease-in-out);
}

.right-menu-toggle-button {
    background: transparent !important;
    border: none !important;
    text-align: right !important;
    justify-content: flex-end;
    display: flex !important;
    width: 100%;

    &:hover {
        background: transparent;

        .right-menu-toggle-icon {
            background-color: white;
        }
    }
}

.right-menu-drawer {
    font-family: Inter;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Inter;
    }

    .ant-drawer-body {
        background: linear-gradient(346.98deg, rgba(0, 0, 0, 0.9) -22.9%, rgba(0, 0, 0, 0.9) 21.42%, rgba(225, 191, 64, 0.9) 114.09%);
        border-radius: 0px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        padding: 0;

        .right-menu-toggle-icon {
            background-color: $color-white;
        }

        .right-menu-toggle-button {
            &:hover {
                .right-menu-toggle-icon {
                    background-color: $color-theme-yellow;
                }
            }
        }

        .menu__footer {
            align-self: self-end;
            flex-grow: 1;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-bottom: 31px;

        }

    }

    .right-menu-logo-block {
        text-align: center;
        margin-bottom: 20px;
        padding: 0 24px;

        .logo-title {
            margin-top: 22px;
            color: $color-theme-yellow;
            font-size: 20px;
            font-weight: 800;
        }

        p {
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            padding-bottom: 24px;
            border-bottom: 0.5px solid #fff;
        }

        img {
            max-width: 120px;
        }
    }

    .new-right-menu-life-events-block {
        margin-bottom: 72px;

        h4 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 12px;
            padding: 0 24px;
        }

        .life-events-list {
            padding-left: 0px;
            font-size: 14px;
            list-style: none;
            width: 100%;
            position: relative;

            li {
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: #141414;
                }

                a {
                    color: $color-white;
                }

                &:hover,
                &.active {

                    a,
                    button {
                        color: $color-theme-yellow;
                        text-decoration: none;

                    }

                    &:before {
                        background-color: $color-theme-yellow;
                    }
                }

                button {
                    background-color: transparent;
                    border: none;
                    text-align: left;
                    width: 100%;
                    height: 100%;
                    padding: 10px 24px;
                    font-size: 14px;

                    &:hover {
                        background-color: #141414;
                    }
                }
            }
        }
    }



    @media screen and (max-width: 350px) {
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }

}

.menu__footer {
    padding: 0 24px;
    bottom: 55px;
    width: 100%;

    .top-button-wrapper {
        padding-bottom: 24px;
        border-bottom: 0.5px solid $color-theme-yellow
    }

    .bottom-button-wrapper {
        padding-top: 24px;
    }

    .bottom-sidebar-link {
        text-align: center;
        margin-top: 25px;
    }
}